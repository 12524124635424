<template>
  <div>
    <b-row>
      <b-col class="col-6">
        <h3>Daftar Pencarian Orang</h3>
        <b-table striped hover :items="listDPO" :fields="dpoFields" show-empty>
          <template v-slot:cell(biodata)="row">
            <!-- <h3 style="text-align: center;" class="wanted-text" >-= {{ row.item.title }} =-</h3><hr/> -->
            <div class="d-flex" @click="viewDPO(row.item)">
              <!-- Foto -->
              <div class="wanted-overlay">
                  <img :src="require('@/assets/images/avatars/8.png')" v-if="row.item.imgurl == null && row.item.sex == 'm' || row.item.valid_img == false && row.item.sex == 'm'" style="width: 150px; height: 150px;">
                  <img :src="require('@/assets/images/avatars/11.png')" v-if="row.item.imgurl == null && row.item.sex == 'f'|| row.item.valid_img == false && row.item.sex == 'f'" style="width: 150px; height: 150px;">
                  <img :src="row.item.imgurl" style="width: 150px; height: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
                  <div class="overlay-text">WANTED</div>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Nama KTP:</strong> <br/><span style="color: yellow;">{{ row.item.fullname }}</span></p>
                <p><strong>Tanggal Lahir:</strong><br/> {{ row.item.dateofbirth }}</p>
              </div>
              <div class="col-12"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <!-- <p><strong>Pekerjaan:</strong><br/> {{ row.item.job }}</p> -->
                <p><strong>Periode DPO:</strong> <br/>{{ row.item.periode }}</p>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-6">
        <h3>Daftar Pencarian Kendaraan</h3>
        <b-table striped hover :items="listDPK" :fields="dpkFields" show-empty>
          <template v-slot:cell(biodata)="row">
            <!-- <h3 style="text-align: center;" class="wanted-text" >-= {{ row.item.title }} =-</h3><hr/> -->
            <div class="d-flex" @click="viewDPK(row.item)">
              <!-- Foto -->
              <div class="wanted-overlay">
                <img :src="require('@/assets/images/avatars/car1.png')" v-if="row.item.imgurl == null || row.item.valid_img == false" style="width: 150px; border-radius: 10%;" class="mr-3">
                <img :src="row.item.imgurl" style="width: 150px; height: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
                <div class="overlay-text-dpk">WANTED</div>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Plate:</strong> <br/><span style="color: yellow;">{{ row.item.plate }}</span></p>
                <p><strong>Model Name:</strong><br/> {{ row.item.modelname }}</p>
              </div>
              <div class="col-12"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Periode DPO:</strong> <br/>{{ row.item.periode }}</p>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      Loading: false,
      dpoFields: [
        {key:"biodata",text:"Biodata"},
      ],
      dpkFields: [
        {key:"biodata",text:"Data Kendaraan"},
      ],
      listDPO: [],
      listDPK: [],
    };
  },
  methods: {
    init(){
      this.getInfoDPO();
    },
    getInfoDPO() {
      axios.post("idp/dpo/list",{id:null}).then((response) => {
        this.listDPO = response.data.data_dpo
        this.listDPK = response.data.data_dpk
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    viewDPO(e){
      this.$router.push({ path: "/idp-warga-detail/"+e.id });
    },
    viewDPK(e){
      this.$router.push({ path: "/idp-kendaraan-detail/"+e.plate });
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>
.wanted-text {
    font-family: 'Impact', sans-serif; /* Gunakan font yang memiliki tampilan tebal dan mencolok */
    font-size: 20px; /* Sesuaikan ukuran font sesuai kebutuhan */
    text-transform: uppercase; /* Ubah teks menjadi huruf besar */
    color: gray; /* Gunakan warna putih untuk teks */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Tambahkan shadow dengan ukuran dan warna yang diinginkan */
}

.wanted-overlay {
    position: relative;
    display: inline-block;
}

.wanted-image {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 10px 20px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}

.overlay-text-dpk {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 10px 20px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}
</style>